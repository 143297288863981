import { render, staticRenderFns } from "./ReportSimpleDataTable.vue?vue&type=template&id=8ebc7378&scoped=true"
import script from "./ReportSimpleDataTable.vue?vue&type=script&lang=js"
export * from "./ReportSimpleDataTable.vue?vue&type=script&lang=js"
import style0 from "./ReportSimpleDataTable.vue?vue&type=style&index=0&id=8ebc7378&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ebc7378",
  null
  
)

export default component.exports